import { getPageDictionary, PagesKeys } from '@/locales';

// TODO: [sc-18705] Melhorar tipagem desse hook e das traduções
export function useTranslation(...pages: PagesKeys[]) {
  const pageTranslations = pages.reduce(
    (acc, page) => ({ ...acc, ...getPageDictionary(page) }),
    {} as Record<string, string>
  );

  const t = (field: string, ...variables: string[] | number[]): string => {
    let translation = pageTranslations[field];

    if (!translation) {
      return '';
    }

    variables.forEach((variable, index) => {
      translation = translation.replace(`{${index}}`, variable.toString());
    });

    return translation;
  };

  return { t };
}
