import { getTranslatedString } from '@/locales';
import env, { serverConfigKeys } from '../../../config/environment';
import { getAbsoluteClientUrl } from '../urls';

export function getDefaultTitleDescription(type: 'title' | 'description') {
  if (type === 'title') {
    return getTranslatedString('common', 'default-page-title');
  }
  if (type === 'description') {
    return getTranslatedString('common', 'default-page-description');
  }
  return undefined;
}

export const getDefaultTitle = () =>
  getTranslatedString('common', 'seo-default-title') || '';

export const getDefaultDescription = () =>
  getTranslatedString('common', 'seo-default-description') || '';

export const getAllStoresTitle = () =>
  `Cupons de desconto, Códigos Promocionais, Ofertas e Promoções | Pelando`;

export const getFacebookAppID = () => serverConfigKeys.FACEBOOK_APP_ID || '';

export const getDefaultOGType = () => 'website';

export const getDefaultOGImage = () => getAbsoluteClientUrl('/favicon.svg');

export const getRssUrl = (path: string) => getAbsoluteClientUrl(`/rss/${path}`);

export const getRobotsWithDisallowSearchIndexFeatureFlag = (robots: string) =>
  env.ALLOW_SEARCH_INDEX ? robots : 'noindex, nofollow';
