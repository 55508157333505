/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';

const DealPageSkeleton = dynamic(() =>
  import('../components/DealPageSkeleton').then((mod) => mod.DealPageSkeleton)
);

const CouponsPageSkeleton = dynamic(() =>
  import('../pages/public/coupon/components/couponsPageSkeleton').then(
    (mod) => mod.default
  )
);

type LoadingRouteProps = PropsWithChildren<{
  loadingDeal: boolean;
  loadingCoupons: boolean;
}>;

const LoadingRoute = ({
  children,
  loadingDeal,
  loadingCoupons,
}: LoadingRouteProps) => {
  /** @ts-ignore */
  if (loadingDeal) return <DealPageSkeleton />;

  /** @ts-ignore */
  if (loadingCoupons) return <CouponsPageSkeleton />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default LoadingRoute;
