import { MittEmitter } from 'next/dist/shared/lib/mitt';
import { useEffect, useState } from 'react';

export function useRouteChange(
  events: MittEmitter<
    | 'routeChangeStart'
    | 'routeChangeComplete'
    | 'routeChangeError'
    | 'beforeHistoryChange'
    | 'hashChangeStart'
    | 'hashChangeComplete'
  >
) {
  const [loadingDeal, setLoadingDeal] = useState(false);
  const [loadingCoupons, setLoadingCoupons] = useState(false);
  useEffect(() => {
    const start = (url: string) => {
      const isCouponPage = !!url.match(/cupons-de-descontos\/[a-z]/);
      const isDealCouponPage = !!url.match(/cupons-de-descontos\/\S+\/\S+/);
      const isTab = url.includes('tab=');
      const previousUrl = window.location.pathname;
      const urlWithoutQuery = url.split('?')[0];
      const isSamePage = previousUrl === urlWithoutQuery;

      if ((url.includes('/d/') || isDealCouponPage) && !isTab) {
        window.scrollTo({ top: 0 });
        setLoadingDeal(true);
      }
      if (isCouponPage && !isTab && !isSamePage) {
        window.scrollTo({ top: 0 });
        setLoadingCoupons(true);
      }
    };
    const end = () => {
      setLoadingDeal(false);
      setLoadingCoupons(false);
    };
    events.on('routeChangeStart', start);
    events.on('routeChangeComplete', end);
    events.on('routeChangeError', end);
    return () => {
      events.off('routeChangeStart', start);
      events.off('routeChangeComplete', end);
      events.off('routeChangeError', end);
    };
  }, [events]);

  return { loadingDeal, loadingCoupons };
}
